.jumbotron {
  /* background: url(../../assets/images/slick-arrow-right.svg) no-repeat 100% 50%; */
  background: #000 url(dingo-background.jpg) no-repeat center center;
  background-size: auto 100%;
  -moz-background-size: auto 100%;
  -webkit-background-size: auto 100%;
  -o-background-size: auto 100%;
  color: #fff;
  height: calc(98vh);
}

.main-section {
    background: #000 url(dingo-background.jpg) no-repeat center center;
    background-size: cover;
    height: calc(93vh);
}

.marginLess {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.fa {
  vertical-align: middle;
  padding-left: 1%;
}
.fa-facebook-official {
  padding-right: 9%;
  cursor: pointer;
}
.btn-primary {
  font-size: 16px;
}

.aligned-center {
  text-align: center;
}

.circle-background {
  background: #5bc0de;
  width: 100px;
  height: 100px;
  /* border-radius: 50%; */
  text-align: center;
  line-height: 100px;
  vertical-align: middle;
}

.shadow-effect {
  webkit-box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.61);
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.61);
}

#post-new {
  background: #5bc0de;
  padding: 28px;
  position: absolute;
  right:0;
  bottom:0;
  z-index: 2000;
  float: right;
  /* border-radius: 75%; */
  cursor: pointer;
}
#landing-space {
  padding-top: 200px;
}
