.spinner {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 50%;
  z-index: 9999;
}

  .spinner > div {
      display: flex;
      justify-content: center;
      align-items: center;
  }
