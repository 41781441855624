.big-checkbox {
  width: 24px !important;
  height: 24px !important;
  opacity: 1 !important;
}

.value-column {
  text-align: center;
  padding-right: 5px;
  margin-right: 5px;
}

.logo-size {
  display: block;
  max-height: 200px;
  width: auto;
}

select.minimal {
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

.tableGrid {
  border: '3px solid #ccc';
  /* width:95%; */
}

.thGrid {
  background-color: 'rgba(0, 0, 0, 0.1)';
  color: '#000';
  border-bottom: '3px solid #ccc';
  text-align: 'center';
}

.input-disabled {
  background-color: 'gray' !important;
}




.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.FormRow:first-child {
  border-top: none;
}

.FormRowLabel {
  width: 15%;
  min-width: 70px;
  padding: 11px 0;
  color: #c4f0ff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}
.FormRowInput:-webkit-autofill {
  -webkit-text-fill-color: #fce883;
  /* Hack to hide the default webkit autofill */
  transition: background-color 100000000s;
  animation: 1ms void-animation-out;
}

.FormRowInput {
  font-size: 16px;
  width: 100%;
  padding: 11px 15px 11px 0;
  color: #fff;
  background-color: transparent;
  animation: 1ms void-animation-out;
}

.FormRowInput::placeholder {
  color: #87bbfd;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}